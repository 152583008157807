<template>
    <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasRight">
        <div class="offcanvas-header">
            <button type="button" class="close" data-bs-dismiss="offcanvas"><i class='bx bx-x'></i></button>
        </div>
        <div class="offcanvas-body">
            <router-link to="/">
                <img src="../../assets/images/buildquick.svg" class="white-logo" alt="image">
            </router-link>
            <div class="sidebar-contact-info">
                <h3>Contact Information</h3>

                <ul class="info-list">
                    <li><i class="flaticon-phone-call-1"></i> <a href="tel:9901234567">+91-7303205771</a></li>

                    <li><i class="flaticon-portfolio"></i><a href="mailto:hello@vnet.com">connect@buildquick.tech</a></li>

                    <li><i class="flaticon-pin"></i> <a href="https://maps.app.goo.gl/At3CH4yKQvifPYAn9" target="_blank">7th Floor, HQ27 The Headquarters, Sector 27 Gurugram Haryana</a>
                    </li>
                </ul>
            </div>
            <ul class="sidebar-social-list">
                <li>
                    <a href="https://www.facebook.com/people/BuildQuick/100092683096270/" target="_blank"><i
                            class='bx bxl-facebook'></i></a>
                </li>
                <li>
                    <a href="http://wa.me/917303205571" target="_blank"><i class='bx bxl-whatsapp'></i></a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/BuildQuick/" target="_blank"><i
                            class='bx bxl-linkedin'></i></a>
                </li>
                <li>
                    <a href="https://www.instagram.com/BuildQuick/" target="_blank"><i class='bx bxl-instagram'></i></a>
                </li>
            </ul>
            <div class="appoint-ment">
                <h6>Subscribe Newsletter</h6>
                <div class="newsletter-input">
                    <form @submit="handleSubmit" class="newsletter-form">
                        <input type="email" placeholder="Enter Your Email Address" id="name3" class="form-control"
                            v-model="email" required>
                        <button class="default-btn disabled" type="submit" style="pointer-events: all; cursor: pointer;">
                            <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            email: '',
        };
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault(); // Prevent form submission
            const data = {
                email: this.email,
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            axios.post('https://node-BuildQuick.onrender.com/v0/subscribenewslatter', data, config)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });

            // Reset form fields
            this.email = '';

        },

    },
    name: 'SidebarModal'
}
</script>

<style lang="scss">
.modal-dialog {
    position: fixed;
    margin: auto;
    width: 420px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.offcanvas-header {
    overflow-y: auto;
    background-color: var(--whiteColor);
    border: none;
    border-radius: 0;

    button.close {
        position: absolute;
        left: -45px;
        top: 15px;
        background-color: transparent;
        border: none;
        text-shadow: unset;
        box-shadow: unset;
        z-index: 4;
        opacity: 1;

        i {
            display: inline-block;
            height: 30px;
            width: 30px;
            line-height: 30px;
            color: var(--whiteColor);
            font-size: 35px;
            transition: var(--transition);
            border-radius: 50%;
        }
    }
}

.offcanvas-body {
    padding: 80px 45px !important;
    z-index: 2;
    position: relative;

    .sidebar-content {
        margin-top: 35px;

        h3 {
            font-size: 22px;
            margin-bottom: 15px;
        }

        .sidebar-btn {
            margin-top: 25px;
        }
    }

    .sidebar-contact-info {
        margin-top: 30px;
        margin-bottom: 35px;

        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }

        .info-list {
            padding-left: 0;
            margin-top: 30px;
            margin-bottom: 0;

            li {
                list-style-type: none;
                font-size: 16px;
                color: var(--bodyColor);
                font-weight: 500;
                margin-bottom: 20px;
                position: relative;
                padding-left: 22px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: var(--bodyColor);
                    text-decoration: none;

                    &:hover {
                        color: var(--mainColor);
                    }
                }

                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    font-size: 16px;
                    color: var(--mainColor);
                }
            }
        }
    }

    .sidebar-social-list {
        list-style-type: none;
        padding-left: 0;
        margin-top: 30px;
        margin-bottom: 35px;

        li {
            display: inline-block;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            i {
                display: inline-block;
                height: 35px;
                width: 35px;
                line-height: 35px;
                background: var(--mainColor);
                text-align: center;
                color: var(--whiteColor);
                font-size: 16px;
                border-radius: 50%;
                -webkit-transition: var(--transition);
                transition: var(--transition);

                &:hover {
                    -webkit-transform: translateY(-5px);
                    transform: translateY(-5px);
                    background-color: var(--blackColor);
                    color: var(--whiteColor);
                }
            }
        }
    }

    .contact-form {
        h3 {
            font-size: 22px;
            margin-bottom: 25px;
        }

        #contactForm .form-group {
            margin-bottom: 20px;

            .form-control {
                height: 55px;
                color: var(--bodyColor);
                -webkit-box-shadow: unset !important;
                box-shadow: unset !important;
                border: 1px solid #f5f5f5;
                background-color: #f5f5f5;
                -webkit-transition: var(--transition);
                transition: var(--transition);
                border-radius: 10px;
                padding: 10px 15px;
                font-size: 15px;

                &:focus {
                    border-color: var(--mainColor);
                    background-color: transparent;
                }

                &::-webkit-input-placeholder {
                    color: var(--bodyColor);
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                }

                &:-ms-input-placeholder {
                    color: var(--bodyColor);
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                }

                &::-ms-input-placeholder {
                    color: var(--bodyColor);
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                }

                &::placeholder {
                    color: var(--bodyColor);
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                }

                &:focus {
                    &:-ms-input-placeholder {
                        color: transparent;
                    }

                    &::-ms-input-placeholder {
                        color: transparent;
                    }

                    &::placeholder {
                        color: transparent;
                    }
                }
            }

            textarea.form-control {
                height: auto;
                padding-top: 15px;
            }
        }

    }

    .list-unstyled {
        li {
            color: red;
            margin-top: 10px;
        }
    }

    div#msgSubmit {
        font-size: 20px;
        margin-top: 15px;
    }
}

.appoint-ment {
    h6 {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .newsletter-input {
        margin-left: 0;

        .newsletter-form {
            position: relative;

            .form-control {
                position: relative;
                height: 55px;
                background-color: #ffffff;
                border-radius: 5px;
                font-size: 15px;
                padding-left: 18px;
            }

            .default-btn {
                position: absolute;
                top: 7px;
                padding: 10px 28px;
                right: 7px;
                bottom: 7px;
                line-height: 0;
                font-size: 32px;

                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                    transition: var(--transition);
                }
            }
        }
    }
}
</style>