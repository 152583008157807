<template>
    <div>
        <TopHeader />
        <MainNavbar />
        <SidebarModal />
        <MainBanner />
        <AboutOurCompany />
        <a href="https://wa.me/917303205571" class="whatsapp_float" target="_blank">
            <i class="fa-brands fa-whatsapp whatsapp-icon"></i>

        </a>
        <ServicesWeOffer />
        <WhyChooseUs />
        <PartnerLogo class="partner-logo-area pt-100 pb-75 bg-F4F8FC" />
        <HowWeWork />
        <OurCaseStudy />
        <!-- <ChooseYourBestPlans /> -->
        <!-- <OurTeamMembers /> -->
        <OurClientTestimonial />
        <ContactUs />
        <BlogPost />
        <FunFect />
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import MainBanner from '../HomeOne/MainBanner'
import AboutOurCompany from '../HomeOne/AboutOurCompany'
import ServicesWeOffer from '../HomeOne/ServicesWeOffer'
import WhyChooseUs from '../HomeOne/WhyChooseUs'
import PartnerLogo from '../Common/PartnerLogo'
import HowWeWork from '../HomeOne/HowWeWork'
import OurCaseStudy from '../HomeOne/OurCaseStudy'
// import ChooseYourBestPlans from '../Common/ChooseYourBestPlans'
// import OurTeamMembers from '../HomeOne/OurTeamMembers'
import OurClientTestimonial from '../HomeOne/OurClientTestimonial'
import ContactUs from '../HomeOne/ContactUs'
import BlogPost from '../HomeOne/BlogPost'
import FunFect from '../Common/FunFect'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'HomeOnePage',
    components: {
        TopHeader,
        MainNavbar,
        SidebarModal,
        MainBanner,
        AboutOurCompany,
        ServicesWeOffer,
        WhyChooseUs,
        PartnerLogo,
        HowWeWork,
        OurCaseStudy,
        // ChooseYourBestPlans,
        // OurTeamMembers,
        OurClientTestimonial,
        ContactUs,
        BlogPost,
        FunFect,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>

<style lang="scss">
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    margin-right: 25px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}
</style>