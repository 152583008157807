<template>
    <div class="funfect-area pb-75">
        <div class="container">
            <div class="funfect-warp">
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div class="single-funfacts-box mb-20">
                            <img src="../../assets/images/counter/cunter1.webp" class="fun-image" alt="image">
                            <h3>300+ </h3>
                            <p>Projects Done</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6  col-sm-6 col-6">
                        <div class="single-funfacts-box mb-20">
                            <img src="../../assets/images/counter/cunter2.webp" class="fun-image" alt="image">
                            <h3>300+</h3>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6 col-6">
                        <div class="single-funfacts-box mb-20">
                            <img src="../../assets/images/counter/cunter3.webp" class="fun-image" alt="image">
                            <h3>10+</h3>
                            <p>Years Experience</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6 col-6">
                        <div class="single-funfacts-box">
                            <img src="../../assets/images/counter/cunter4.webp" class="fun-image" alt="image">
                            <h3>30+</h3>
                            <p>Team Size</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/cb-shape.webp" class="blog-count-shape" alt="">
    </div>
</template>

<script>
export default {
    name: 'FunFect'
}
</script>

<style lang="scss">
.single-funfacts-box {
    position: relative;
    padding-left: 140px;
    margin-bottom: 25px;

    .fun-image {
        position: absolute;
        left: 0;
    }

    h3 {
        font-weight: 800;
        font-size: 55px;
        color: var(--mainColor);

        .odometer-formatting-mark {
            display: none;
        }

        .content {
            position: relative;
            top: 2px;
        }
    }
}

.funfect-area {
    position: relative;

    .blog-count-shape {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .single-funfacts-box {
        padding-left: 0;
        text-align: center;
        margin-bottom: 25px;

        .fun-image {
            width: 50px;
            position: relative;
            margin-bottom: 8px;
            left: 0;
        }

        h3 {
            font-size: 30px;
            margin-bottom: 0;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-funfacts-box {
        padding-left: 100px;

        .fun-image {
            width: 75px;
            top: 4px;
            left: 0;
        }

        h3 {
            font-size: 38px;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-funfacts-box {
        padding-left: 75px;

        .fun-image {
            width: 65px;
            top: 10px;
        }

        h3 {
            font-size: 35px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .single-funfacts-box {
        padding-left: 80px;

        .fun-image {
            width: 70px;
            top: 12px;
        }

        h3 {
            font-size: 45px;
        }
    }
}</style>