<template>
    <div class="service-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="service-big-widget">
                        <div class="development">
                            <h2>{{ serviceDetail.title }}</h2>
                            <p>{{ serviceDetail.paragraph1 }}</p>
                            <h2>{{ serviceDetail.hading2 }}</h2>
                            <p class="last-p">
                                {{ serviceDetail.paragraph2 }}
                            </p>
                            <h4>{{ serviceDetail.whatYouWillGet }}</h4>
                            <ul v-for="item in serviceDetail.listOffering" :key="item.id">
                                <li>
                                    <i class="flaticon-full-stop"></i>
                                    <b> {{ item.li }} </b>
                                </li>
                                <p>{{ item.text }}</p>
                            </ul>
                        </div>
                        <div>
                            <h4>{{ serviceDetail.hading3 }}</h4>
                            <ul v-for="item in serviceDetail.listReasons" :key="item.id">
                                <li>
                                    <i class="flaticon-full-stop"></i>
                                    <b> {{ item.li }} </b>
                                </li>
                                <p>{{ item.reason }}</p>
                            </ul>
                        </div>
                        <div class="service-details-image">
                            <img src="../../assets/images/video/service-details.jpg" alt="image" />
                        </div>
                        <div class="paragraph">
                            <p>{{ serviceDetail.paragraph3 }}</p>
                            <div class="stylish-p">
                                <p>{{ serviceDetail.quote }}</p>
                                <i class="flaticon-quote"></i>
                            </div>
                            <p>{{ serviceDetail.pragraph4 }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-details-small-widget">
                        <!-- <div class="input-box">
                            <form>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search Something"
                                        aria-label="Search..." aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i
                                            class='bx bx-search'></i></button>
                                </div>
                            </form>
                        </div> -->
                        <div class="category">
                            <h4>Category</h4>
                            <ul>
                                <li>
                                    <a href="/service-details?service=web development">
                                        Web Development
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/service-details?service=app development">
                                        App Development
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/service-details?service=uiux design">
                                        UI/UX
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/service-details?service=artificial intllegence">
                                        Artificial Intelligence
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/service-details?service=blockchain">
                                        Blockchain
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/service-details?service=devops services">
                                        IT Resources
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="questions">
                            <h3>Have any Questions? Contact us Today!</h3>

                            <form class='form-control'>
                                <p class='form-label'>Full Name</p>
                                <input type='text' name='full-name' />
                                <p class='form-label'>Email</p>

                                <input type='text' name='email' />
                                <p class='form-label'>Phone</p>

                                <input type='number' name='phone' />
                                <p class='form-label'>Query</p>

                                <textarea type='text' name='query' />
                                <input type='submit' class='form-submit' />

                            </form>
                            <div class="questions">
                                <a href="tel:3128959800" class="icon">
                                    <i class="flaticon-phone-call-1"></i>
                                </a>
                                <br />
                                <a href="tel:3128959800">+91-7303205771</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='mt-4'>
                    <h1 class='text-center'>OUR PORTFOLIO</h1>
                    <div class="row">
                        <div class="col-lg-4" v-for="portfolio in portfolioData" :key="portfolio.id">
                            <div class="frist-widget">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="latest-box">
                                            <img :src="portfolio.showImage" alt="image" class='portfolio-image'>
                                            <div class="latest-content  d-flex">

                                                <div class="explore-btn">
                                                    <router-link :to="`${portfolio.projectDetails}`"> {{ portfolio.title
                                                        }}
                                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- First Modal -->
        <div class='modalcontainer' v-if="showModal && isAppDevelopment">
            <div class="poopup22">
                <button class="close-btn" @click="attemptCloseModal" :disabled="!canCloseModal">X</button>
                <h2>Let's Connect</h2>
                <p><b>Find solution together</b></p>

                <div class="row align-items-center flex-column">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <input v-model="form1.email" type="email" class="form-control" placeholder="Your Email" />
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <input v-model="form1.phone" type="number" class="form-control" placeholder="Your Phone" />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="submit-btn text-center">
                            <button @click="submitForm1" type="submit" class="default-btn">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Second Modal -->
        <div class='modalcontainer height50' v-if="showSecondModal && isAppDevelopment">
            <div class="poopup22">
                <button class="close-btn" @click="attemptCloseSecondModal" :disabled="!canCloseSecondModal">X</button>
                <h2>Additional Information</h2>
                <p><b>Help us serve you better</b></p>

                <div class="row align-items-center flex-column">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <input v-model="form2.name" type="text" class="form-control" placeholder="Your Name" />
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <textarea v-model="form2.query" class="form-control" placeholder="Your Query"
                                rows="4"></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="submit-btn text-center">
                            <button @click="submitForm2" type="submit" class="default-btn">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { services, portfolioData } from "../../constants/services";

export default {
    created() {
        this.getParam();
        this.showModalAfterDelay();
    },
    methods: {
        getParam() {
            // Accessing route params in the created hook
            const { service } = this.$route.query;
            this.serviceDetail = service ? services[service?.toLocaleLowerCase()] : services["web development"];
            this.portfolioData = portfolioData;
            this.isAppDevelopment = service?.toLowerCase() === 'app development';
        },
        showModalAfterDelay() {
            if (this.isAppDevelopment) {
                setTimeout(() => {
                    this.showModal = true;
                }, 10000);
            }
        },
        closeModal() {
            this.showModal = false;
        },
        submitForm1() {
            // Here you would typically send the form data to your backend
            console.log('Form 1 submitted:', this.form1);
            this.canCloseModal = true;
            this.closeModal();

            // Show the second form after 5 seconds
            if (this.isAppDevelopment) {
                setTimeout(() => {
                    this.showSecondModal = true;
                    this.canCloseSecondModal = false; // Reset for the second form
                }, 5000);
            }
        },
        closeSecondModal() {
            this.showSecondModal = false;
        },
        submitForm2() {
            // Here you would typically send the form data to your backend
            console.log('Form 2 submitted:', this.form2);
            this.canCloseSecondModal = true;
            this.closeSecondModal();
        },
        attemptCloseModal() {
            if (this.canCloseModal) {
                this.closeModal();
            } else {
                alert("Please submit the form before closing.");
            }
        },
        attemptCloseSecondModal() {
            if (this.canCloseSecondModal) {
                this.closeSecondModal();
            } else {
                alert("Please submit the form before closing.");
            }
        }
    },
    data() {
        return {
            serviceDetail: null,
            showModal: false,
            showSecondModal: false,
            form1: {
                email: '',
                phone: ''
            },
            form2: {
                name: '',
                query: ''
            },
            canCloseModal: false,
            canCloseSecondModal: false,
            isAppDevelopment: false,
        }
    },
    name: 'ServicesDetails'
}
</script>

<style lang="scss">
.portfolio-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(3px 3px 3px gray)
}

.modalcontainer {
    position: fixed;
    height: 40%;
    width: 40%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    margin-top: 10%;
    border-radius: 15px;
    box-shadow: 0 0 339px 400vh #0000005e;
    z-index: 999;
}

.height50 {
    height: 50%;
}

.poopup22 {
    text-align: center;
    height: 100%;
    margin-top: 5px;
}

.popupform-control {
    background: #FFFFFF;
    position: relative;
    height: 60px;
    border-radius: 5px;
    border: none;
    padding-bottom: 10px;
    color: #888888;

    &:focus {
        box-shadow: unset;
    }
}

.popupform-submit {
    padding: 18px 35px;
    border-radius: 5px;
    background-color: var(--mainColor);
    font-weight: 500;
    color: var(--whiteColor);
    font-size: 16px;
    position: relative;
    display: inline-block;
    border: transparent;
    transition: var(--transition);
}

.poopup22 {
    text-align: center;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.submit-btn .default-btn {
    background-color: #ff414b;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.explore-btn {
    height: 40px;
    display: flex;
    align-items: center;
    place-content: center;
    width: 100%;
}

.latest-box .latest-content .explore-btn a {
    padding: unset !important;
    display: flex !important;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around !important;
    border-radius: 0 !important;


}

.latest-box .latest-content {
    padding: unset !important;
    position: absolute;
    width: 50%;
    align-items: center;
    margin-bottom: 25px;
    place-content: center;
    justify-content: center !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.form-label {
    text-align: start
}

.form-control input,
.form-control textarea {
    width: 100%;
}

.form-submit {
    margin-top: 10px;
    border: none;
    background: linear-gradient(89.39deg, #ef5d5d -25.46%, #FF414B 133.39%);
    color: white;
    padding: 2%;
    cursor: pointer;
}

.input-box {
    background: #F4F8FC;
    border-radius: 5px;
    padding: 20px 22px;
    margin-bottom: 30px;

    .form-control {
        background: #FFFFFF;
        position: relative;
        height: 60px;
        border-radius: 5px;
        border: none;
        padding-left: 20px;
        color: #888888;

        &:focus {
            box-shadow: unset;
        }
    }

    .btn-outline-secondary {
        border: none;
        background: #ef5d5d;
        border-radius: 0px 5px 5px 0px;
        padding-left: 20px;

        i {
            font-size: 29px;
            color: #ffffff;
            left: -2px;
            bottom: -3px;
            position: relative;
        }
    }
}

.category {
    margin-bottom: 30px;

    h4 {
        font-size: 22px;
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            a {
                position: relative;
                padding: 18px 28px;
                background: #F4F8FC;
                border-radius: 5px;
                color: var(--headingColor);
                font-weight: 500;
                font-size: 16px;
                display: block;
                position: relative;
                z-index: 1;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    height: 100%;
                    width: 0%;
                    opacity: 0;
                    border-radius: 5px;
                    visibility: hidden;
                    z-index: -1;
                    transition: var(--transition);
                    background: linear-gradient(90.08deg, #ef5d5d -26.31%, #FF414B 117.96%);
                }

                &:hover {
                    &::before {
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                    }

                    color: var(--whiteColor);

                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                        transition: var(--transition);
                    }
                }

                img {
                    position: absolute;
                    right: 28px;
                    top: 28px;
                    font-size: 22px;
                    color: var(--hoverColor);
                    transition: var(--transition);
                }
            }
        }
    }
}

.service-details-small-widget {
    padding-left: 20px;
}

.case-big-widget {
    padding-right: 20px;
}

.development {
    h4 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }

    .last-p {
        margin-bottom: 20px;
    }

    h3 {
        font-size: 32px;
        letter-spacing: -0.03em;
        margin-bottom: 5px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 30px;
        margin-top: 20px;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 18px;

            i {
                position: absolute;
                left: -23px;
                top: -24px;
                font-size: 58px;
                color: #ef5d5d;
            }
        }
    }
}

.service-details-image {
    margin-bottom: 30px;

    img {
        border-radius: 5px;
    }
}

.stylish-p {
    padding: 55px 60px;
    background: #F4F8FC;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;

    i {
        font-size: 41px;
        color: #ef5d5d;
        position: absolute;
        right: 77px;
        bottom: 18px;
    }

    p {
        font-style: italic;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.questions {
    text-align: center;
    padding: 65px 35px 35px 35px !important;
    background: linear-gradient(89.39deg, #ef5d5d -25.46%, #FF414B 133.39%);
    border-radius: 5px;

    .icon {
        width: 60px;
        height: 60px;
        background-color: var(--whiteColor);
        border-radius: 50%;
        text-align: center;
        color: var(--mainColor);
        margin: 20px 50px 20px 20px;
        position: relative;
        display: inline-block;
        line-height: 66px;
        font-size: 22px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 50px;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            top: -20px;
            left: -20px;
            right: -20px;
            bottom: -20px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            z-index: -1;
            -webkit-animation: ripple 5s linear infinite;
            animation: ripple 5s linear infinite;
        }
    }

    h3 {
        font-size: 22px;
        margin-bottom: 20px;
        color: #ffffff;
        max-width: 259px;
        margin-left: auto;
        margin-right: auto;
    }

    a {
        font-size: 18px !important;
        color: var(--whiteColor);
        font-weight: 700;

        &:hover {
            color: var(--hoverColor);
        }
    }
}

.close-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>