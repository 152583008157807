
export const services = {
  "web development": {
    title: "Web Development",
    paragraph1:
      "At BuildQuick, we redefine web development by creating dynamic, high-performing platforms that evolve alongside your business. Our end-to-end services encompass design, development, and deployment, ensuring your website is not only functional and responsive but also secure. We focus on crafting compelling user experiences that captivate your audience and drive sustainable growth.",
    paragraph2: "",
    hading2: "Our Approach",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: " Planning & Strategy", text:"We define clear goals and develop a custom strategy tailored to your business needs, ensuring your website is built for success from day one."},
      { id: 2, li: " Front-End Development", text: "Our team delivers sleek, responsive designs that enhance user experience across all devices, ensuring your website is fast, interactive, and visually striking." },
      { id: 3, li: "Back-End Development", text: "With robust back-end frameworks, we create secure, scalable systems that handle data and functionality effortlessly, ensuring your website operates smoothly." },
      { id: 4, li: "API Integration", text: "We integrate custom APIs and third-party services to extend your website’s capabilities, making it more dynamic, functional, and future-proof." },
      { id: 5, li: "Web Servers & Hosting", text: "Our web hosting solutions guarantee fast load times, uptime reliability, and enhanced security, ensuring your website is always performing at its best." },
    ],
    hading3: "Why Choose Us?",
    whyChooseUs:"",
    listReasons: [
      { id: 1, li: "Tailored Solutions:", reason:" We don’t believe in one-size-fits-all. Our web apps are meticulously crafted to align with your unique business objectives, ensuring a perfect fit for your needs." },
      { id: 2, li: "Scalability & Performance:", reason: " Your business is ever-evolving, and so should your web app. We design solutions that not only perform flawlessly today but are also built to scale seamlessly as you grow." },
      { id: 3, li: "Security & Reliability:", reason:" Trust is key in the digital landscape. We prioritize top-notch security measures, ensuring your site is safe, reliable, and fortified against threats, so you can focus on what matters most – your business." },
      { id: 4, li: "Ready to elevate your digital presence? ",reason:"Contact us today to discuss how we can bring your vision to life!" },
    ],

    banner: '../../assets/images/video/service-details.jpg',
    // src: "../../assets/images/video/service-details.jpg",
    paragraph3: "",
    quote:
      "The web development process is an art of converting imagination into reality",
    pragraph4:
      "Our team assures you timely delivery of the task and we stay connected with you at every step of the process to assure that all things are going on track and meet your expectations.",
  },
  "app development": {
    title: "App Development: Your Vision, Our Expertise – Together, We Innovate!",
    paragraph1:
      "In a world where mobile experiences shape how we connect, engage, and thrive, your app deserves to be exceptional. At BuildQuick, we don’t just create applications; we forge powerful digital experiences that captivate users and propel your brand to new heights.",
    paragraph2: "",
    hading2: "Unlock the Potential of Your Ideas",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Tailored App Solutions", text: "Every great app starts with a unique vision. Whether you need a native app designed for flawless performance on iOS or Android, or a hybrid app that marries versatility with functionality, our expert team is here to bring your concept to life with precision and creativity." },
      { id: 2, li: "Cross-Platform Brilliance", text: "Why limit your reach? With our cutting-edge cross-platform development, we ensure your app shines on every device, providing a seamless experience that resonates with all users. Embrace innovation while keeping costs in check!" },
      { id: 3, li: "Design That Delivers", text:"First impressions matter! Our UI & UX designers go beyond aesthetics, crafting intuitive and immersive interfaces that keep users engaged and coming back for more. Your app won’t just look good – it will feel right, too." },
      { id: 4, li: "Seamless App Store Launches",text:"Launching your app should be a celebration, not a chore. We simplify the app store submission process, ensuring your app meets all requirements and stands out in a crowded marketplace. Get ready to make waves when you hit that launch button!" },
      { id: 5, li: "Ongoing Support & Insights",text:`Your app’s success is our priority, even after launch. With our dedicated maintenance and analytics services, we keep your application optimized and relevant. We analyze user behavior, implement updates, and enhance performance to ensure you’re always a step ahead.` },
    ],
    hading3: "Why Choose Us?",
    whyChooseUs:"",
    listReasons: [
      { id: 1, li: "Unmatched Expertise:", reason:"With a proven track record in app development, our team of seasoned professionals brings a wealth of knowledge and experience to the table. We stay ahead of industry trends, leveraging the latest technologies to deliver innovative solutions tailored to your needs." },
      { id: 2, li: "Custom Solutions for Unique Goals:", reason: "We understand that every business is different. That’s why we take the time to listen to your vision and create bespoke applications that align with your specific objectives. Your success is our mission, and we build apps that reflect your brand's identity and goals." },
      { id: 3, li: "User-Centric Design:", reason:"We prioritize your users’ experience above all else. Our UI/UX experts focus on crafting intuitive interfaces that not only look stunning but also provide seamless functionality. We design with your audience in mind, ensuring high engagement and satisfaction." },
      { id: 4, li: "Agile Development Process:",reason:"Flexibility is key in today’s fast-paced digital landscape. Our agile development methodology allows for iterative improvements and real-time collaboration, ensuring your app evolves based on feedback and remains aligned with your business objectives." },
      { id: 5, li: "Robust Security Measures:",reason:"In a world where data breaches are a constant threat, we prioritize the security and privacy of your app. Our rigorous security protocols protect sensitive information and ensure your application is safe and reliable, giving you peace of mind." },
      { id: 6, li: "Proven Results:",reason:"Our portfolio speaks for itself. We have successfully delivered numerous high-quality applications that drive user engagement and business growth. Join our growing list of satisfied clients who have transformed their ideas into successful mobile solutions." },
      { id: 7, li: "Ready to Ignite Your App Development Journey?",reason:"Don’t just dream about your app – make it a reality! Contact us today for a free consultation, and let’s build something extraordinary together. Your success story begins here!" },
    ],
    banner: "",
    paragraph3: "",
    quote:
      "App development is not just about coding; it's about creating experiences that captivate and delight users.",
    pragraph4:
      "We assure you to create an immersive experience that fits in the palm of your hand.",
  },
  "uiux design": {
    title: "UI/UX Design",
    paragraph1:
      "We combine the process of creating user interfaces and experiences for all your applications. While focusing on the visual aspects and aesthetics whilst coming up with the overall feel, usability and the satisfaction of the user interacting with the product.",
    paragraph2: "",
    hading2: "A few key Aspects under UI/UX Design are mentioned Below -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "User Research" },
      { id: 2, li: "Wireframing and Protottyping" },
      { id: 3, li: "Microinteractions" },
      { id: 4, li: "User Testing and Feedback" },
      { id: 5, li: "Continious Improvements" },
    ],

    banner: "",
    paragraph3: "",
    quote:
      "Design is not just a visual layer; it's an emotional and intellectual connection between the product and the user.",
    pragraph4:
      "Our goal is to create a seamless, intuitive and delightful experience for the users.",
  },
  "it resources": {
    title: "IT Resources",
    paragraph1:
      "IT Resources refers to our in house team coming to your office/workspace to help you build or maintain your resources providing you with the best background technical support on all fronts.",
    paragraph2: "",
    hading2:
      "It includes use of miltiple softwares for your software, a few of them are listed below -",
    whatYouWillGet: "Front End and Back End Development - ",
    listOffering: [
      { id: 1, li: "Front End Development - React, Sass, HTML5, vue.JS, Angular " },
      { id: 2, li: "Back End Development -NodeJS, MYSQL, MongoDB, PostgreSQL" },
      { id: 3, li: "React Js" },
      { id: 4, li: "Github" },
      { id: 5, li: "Incident Management and Retrospect" },
    ],
    banner: "",
    paragraph3: "",
    quote:
      "The greatest benefits of AI in IT Resources will come from augmenting human capabilities, not replacing them.",
    pragraph4:
      "Our team assures you to give the best on site results for your requirements within a limited time span. It is the flexibility of softwares and location that makes us unique from others and we stand out by giving you customized solutions for your needs.",
  },
  "artificial intllegence": {
    title: "Artificial Intellegence",
    paragraph1:
      "DevOps Services combine the Development and Operations Teams in rArtificial Intelligence as a service is a cloud based offering wherein we leaverage the power of Machine Learning, Natural Language Processing, data analytics through API's or  pre built models.",
    paragraph2:
      "BuildQuick offers you a cost effective and convenient method to harness the power of AI without the complexities and resource requirements of building and managing their own infrastructure.",
    hading2: "We provide you the following benefits under this segment -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Rapid IT Development" },
      { id: 2, li: "Better Accessability and Scalability" },
      {
        id: 3,
        li: "Continious Innovation and integration of latest AI Tools and Processes",
      },
      { id: 4, li: "AI Bots and Virtual Assistant Creation" },
      { id: 5, li: "Data Analysis and Insights " },
    ],

    banner: "",
    paragraph3:
      "Our aim is to ease the customer journey process and make it as smooth from them as possible, be it a query, getting more traffic or resolving any issue, it'll be a one stop solution to their problems.",
    quote:
      "Artificial intelligence will empower us to do more, achieve more, and create more. It is a tool that expands our horizons and unlocks new possibilities.",
    pragraph4:
      "Our team assures you timely delivery of the task and we stay connected with you at every step of the process to assure that all things are going on track and meet your expectations.",
  },
  blockchain: {
    title: "Blockchain",
    paragraph1:
      "BuildQuick allows businesses to leverage the benefits of blockchain, such as decentralization, immutability, and transparency, without the need for extensive in-house expertise or infrastructure investment.",
    paragraph2:
      "We  provide a platform or framework that simplifies the development, deployment, and management of blockchain applications.",
    hading2: "We provide you the following under Blockchain services -",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Infrastructure" },
      { id: 2, li: "Network Management" },
      { id: 3, li: "Security and Stability" },
      { id: 4, li: "Support and Training" },
      { id: 5, li: "Developer Options and Customizations" },
    ],

    banner: "",
    paragraph3: "",
    quote:
      "Blockchain is the future of cybersecurity; it has the potential to revolutionize the way we secure our digital assets",
    pragraph4:
      "Our aim is to give your organization a secured and a efficient network to communicate and share all sorts of documents and data without any hassle.",
  },
  "dev ops": {
    title: "DevOps Services",
    paragraph1:
      "DevOps Services combine the Development and Operations Teams in order to streamline the software development and the deployment process. The team’s goal is to foster collaboration, improve efficiency, and enhance the overall quality and reliability of software delivery.",
    paragraph2: "",
    hading2: "Here are the common practices and services provided under this Service - ",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Configuration Management" },
      { id: 2, li: "Version Control" },
      { id: 3, li: "Continious Delivery and Intergration" },
      { id: 4, li: "Incident Management and Retrospect" },
      { id: 5, li: "Aglie and Lean Methodologies" },

    ],
    banner: '',
    paragraph3: "",
    quote:
      "DevOps is the union of people, process, and technology to enable continuous delivery of value to customers.",
    pragraph4:
      "In the modern world, DevOps Services unlock the key to agility, efficiency and innovation and that is what drives us to work even harder for your applications.",
  },
  "qa tester": {
    title: "QA Testing",
    paragraph1:
      "DevOps Services combine the Development and Operations Teams in order to streamline the software development and the deployment process. The team’s goal is to foster collaboration, improve efficiency, and enhance the overall quality and reliability of software delivery.",
    paragraph2: "",
    hading2: "Here are the common practices and services provided under this Service - ",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "Functional Testing" },
      { id: 2, li: "Performance Testing" },
      { id: 3, li: "Bug Reporting" },
      { id: 4, li: "User Interface Testing" },
      { id: 5, li: "Test planning and Test Case Developments" },

    ],
    banner: '',
    paragraph3: "",
    quote:
      "Quality is not an act, it is a habit.",
    pragraph4:
      "QA testing is an iterative process that involves continuous testing, bug fixing, and retesting until the software meets the desired quality standards.",
  },
  "maintenance": {
    title: "Maintenance",
    paragraph1:
      "BuildQuick offers you high quality maintenance for all your softwares and applications assuring you and your user smooth functioning and  a dfelightful experience.",
    paragraph2: "",
    hading2: "Here are the common practices and services provided under this Service - ",
    whatYouWillGet: "",
    listOffering: [
      { id: 1, li: "IT Infrastructure Maintenance" },
      { id: 2, li: " Updates in the services/ products" },
      { id: 3, li: "Software Maintenence" },
      { id: 4, li: "IT Consulting" },
      { id: 5, li: "Security Management" },

    ],
    banner: '',
    paragraph3: "",
    quote:
      "DevOps is the union of people, process, and technology to enable continuous delivery of value to customers.",
    pragraph4:
      "In the modern world, DevOps Services unlock the key to agility, efficiency and innovation and that is what drives us to work even harder for your applications.",
  },
};

export const portfolioData = [
  {
    id: 1,
    showImage: require("../assets/images/projects/project4.webp"),
    title: "Khawishh",
    projectDetails: "portfolio-details?service=khawishh",
    subtitle: "Fashion Store",

  },
  {
    id: 2,
    showImage: require("../assets/images/projects/project5.webp"),
    title: "BrainWoo",
    projectDetails: "portfolio-details?service=brain-woo",
    subtitle: "Education Platform",
  },
  {
    id: 3,
    showImage: require("../assets/images/projects/project6.webp"),
    title: "ITP Media",
    subtitle: "Media Company",
    projectDetails: "portfolio-details?service=itp-media",
  },
  {
    id: 4,
    showImage: require("../assets/images/projects/project1.webp"),
    title: "Growpital",
    projectDetails: "portfolio-details?service=grow-pital",
    subtitle: "Agro Based Investment",

  },
  {
    id: 5,
    showImage: require("../assets/images/projects/project3.webp"),
    title: "EastMojo",
    projectDetails: "portfolio-details?service=east-mojo",
    subtitle: "News App",

  },
  {
    id: 6,
    showImage: require("../assets/images/projects/project2.webp"),
    title: "BitMemoir",
    projectDetails: "portfolio-details?service=bit-memoir",
    subtitle: "BloackChain Certifications",

  },
  {
    id: 7,
    showImage: require("../assets/images/portfolio/app/driveassist/Splash.png"),
    title: "Drive Assist",
    projectDetails: "portfolio-details?service=drive-assist",
    subtitle: "Private Car Driver",
  },
  {
    id: 8,
    showImage: require("../assets/images/portfolio/app/PostMoney/PM.png"),
    title: "Post Money",
    projectDetails: "portfolio-details?service=post-money",
    subtitle: "Private Car Driver",
  }, {
    id: 9,
    showImage: require("../assets/images/portfolio/app/Routes/Routes111.png"),
    title: "Routes",
    projectDetails: "portfolio-details?service=routes",
    subtitle: "Private Car Driver",
  },
]