<template>
    <div class="footer-area">
        <div class="container">
            <div class="inner-footer pt-100 pb-75">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <img src="../../assets/images/buildquick.svg" class="main-footer-logo" alt="image">
                            </div>
                            <p class="footer-p">Partner with us and experience your business growing in the market with
                                our
                                Technological solutions customized as per your needs</p>
                            <ul class="social-link">
                                <li>
                                    <a href="https://www.facebook.com/people/BuildQuick/100092683096270/"
                                        target="_blank"><i class='bx bxl-facebook'></i></a>
                                </li>
                                <li>
                                    <a href="http://wa.me/917303205571" target="_blank"><i
                                            class='bx bxl-whatsapp'></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/BuildQuick/" target="_blank"><i
                                            class='bx bxl-instagram'></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/BuildQuick/" target="_blank"><i
                                            class='bx bxl-linkedin'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <div class="footer-widget">
                            <h3>Quick Link</h3>
                            <ul class="footer-link">
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/">
                                        Home
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/about">
                                        About Us
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/contact">
                                        Contact
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/case-study">
                                        Case Study
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/terms-conditions">
                                        Terms of Service
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/privacy-policy">
                                        Privacy Policy
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="footer-widget">
                            <h3>Services</h3>
                            <ul class="footer-link">
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/service-details?service=web development">
                                        Web Development
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/service-details?service=app development">
                                        App Development
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/service-details?service=uiux design">
                                        UI/UX
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/service-details?service=artificial intllegence">
                                        Artificial Intelligence
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/service-details?service=blockchain">
                                        Blockchain
                                    </router-link>
                                </li>
                                <li>
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    <router-link to="/service-details?service=it resources">
                                        IT Resources
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="footer-widget">
                            <h3>Quick Contact</h3>
                            <ul class="footer-contact">
                                <li>
                                    <i class="flaticon-email"></i>
                                    <h6>Email Address:</h6>
                                    <a href="mailto:hello@vnet.com">connect@buildquick.tech</a>
                                </li>
                                <li>
                                    <i class="flaticon-pin"></i>
                                    <h6>Our Location:</h6><a href="https://maps.app.goo.gl/At3CH4yKQvifPYAn9"
                                        target="_blank">
                                        7th Floor, HQ27 The Headquarters, Sector 27 Gurugram Haryana</a>
                                </li>
                                <li>
                                    <i class="flaticon-phone-call"></i>
                                    <h6>Our Phone:</h6>
                                    <!-- <a href="tel:3128959800">(312) 895-9800</a>/ -->
                                    <a href="tel:026935865">+917303205771</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-shape text-center">
                <img src="../../assets/images/shapes/footer-shape.webp" class="footer-frist-shape" alt="image">
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="inner-copy">
                    <p>© Created with love by team
                        <a href="/">BuildQuick</a>
                    </p>
                    <img src="../../assets/images/shapes/copyr.webp" class="copyright-shape" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainFooter'
}
</script>

<style lang="scss">
.footer-area {
    background-color: var(--headingColor);
    position: relative;
    z-index: 1;

    .footer-frist-shape {
        position: absolute;
        left: 23%;
        right: 0;
        text-align: center;
        top: -134px;
        z-index: -1;
    }
}

.col-lg-4 {
    &:nth-child(1) {
        .footer-widget {
            padding-right: 40px;
        }
    }
}

.col-lg-2 {
    &:nth-child(2) {
        .footer-widget {
            margin-left: 30px;
        }
    }
}

.col-lg-3 {
    &:nth-child(3) {
        .footer-widget {
            margin-left: 80px;
        }
    }

    &:nth-child(4) {
        .footer-widget {
            margin-left: 35px;
        }
    }
}

.main-footer-logo {


    max-width: 40%;
    height: auto;

}

.footer-widget {
    margin-bottom: 25px;

    .footer-logo {
        margin-bottom: 20px;
    }

    .footer-p {
        color: #CCCCCC;
        margin-bottom: 20px;
    }

    .social-link {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            display: inline-block;
            padding-right: 12px;

            &:last-child {
                padding-right: 0;
            }

            a {
                display: inline-block;
                width: 55px;
                height: 55px;
                background: rgba(255, 255, 255, 0.05);
                border-radius: 50%;
                text-align: center;
                font-size: 20px;
                color: var(--whiteColor);
                line-height: 60px;

                &:hover {
                    background-color: var(--mainColor);
                }
            }
        }
    }

    h3 {
        font-size: 22px;
        letter-spacing: 0.05em;
        margin-bottom: 30px;
        color: var(--whiteColor);
    }

    .footer-link {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 14px;

            a {
                color: #CCCCCC;
            }

            img {
                position: absolute;
                left: 0;
                transition: var(--transition);
                top: 7px;
            }

            &:hover {
                a {
                    color: var(--hoverColor);
                }

                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                    transition: var(--transition);
                }
            }
        }
    }

    .footer-contact {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            position: relative;
            margin-bottom: 18px;
            padding-left: 30px;
            font-weight: 400;
            color: #CCCCCC;

            h6 {
                color: var(--whiteColor);
                font-weight: 500;
            }

            a {
                color: #CCCCCC;
                padding-right: 3px;

                &:hover {
                    color: var(--mainColor);
                }
            }

            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: var(--hoverColor);
            }
        }
    }
}

.copyright-area {
    text-align: center;

    p {
        color: #CCCCCC;
        margin-bottom: 0;

        a {
            color: var(--hoverColor);
        }
    }

    .inner-copy {
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;

        .copyright-shape {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}
</style>