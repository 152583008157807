<template>
  <div class="contact-area ptb-100">

    <!-- Success Popup Modal -->
    <div v-if="showSuccessPopup" class="modal-overlay">
      <div class="modal-content">
        <span class="close-button" @click="showSuccessPopup = false">&times;</span>
        <p>Message sent successfully!</p>
      </div>
    </div>



    <div class="container">
      <div class="section-title">
        <span>Get in Touch</span>
        <h2>Don't hesitate to Send a Message</h2>
      </div>
      <div class="contact-widget">
        <div class="inner-massage">
          <div class="row">
            <div class="col-lg-4">
              <div class="massage-box">
                <div class="icon">
                  <i class="flaticon-pin"></i>
                </div>
                <div class="content">
                  <h5>Our Address</h5>
                  <p>
                    2nd Floor, VentureX, 389-390, Sector 29, Gurgram Haryana,
                    122002
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="massage-box">
                <div class="icon">
                  <i class="flaticon-phone-call-1"></i>
                </div>
                <div class="content">
                  <h5>Our Phone</h5>
                  <p>
                    <a href="tel:3128959800">(91) 73032-05771</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="massage-box">
                <div class="icon">
                  <i class="flaticon-email"></i>
                </div>
                <div class="content">
                  <h5>Our Mail</h5>
                  <p>
                    <a href="mailto:hello@vnet.com">connect@buildquick.tech</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-form-area">
          <div v-if="success" class="alert alert-success" role="alert">
            User updated successfully
</div>
          <form @submit="handleSubmit" class="contact-from">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="text"
                    id="name1"
                    class="form-control"
                    placeholder="First Name"
                    v-model="firstName"
                  />
                  <p v-if="FirstNameError" class="error-message">
                    Please enter your First Name
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="text"
                    id="name1"
                    class="form-control"
                    placeholder="Last Name"
                    v-model="lastName"
                  />
                  <p v-if="lastNameError" class="error-message">
                    Please enter your Last Name
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="email"
                    id="name3"
                    class="form-control"
                    placeholder="Your Email"
                    v-model="email"
                  />
                  <p v-if="emailError" class="error-message">
                    Please enter a valid email address
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="text"
                    id="name1"
                    class="form-control"
                    placeholder="Your Phone"
                    v-model="mobile"
                  />
                  <p v-if="mobileError" class="error-message">
                    Please enter a valid mobile number
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="text"
                    id="name1"
                    class="form-control"
                    placeholder="Company Name"
                    v-model="company_name"
                  />
                  <p v-if="companyError" class="error-message">
                    Please enter a Company Name
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <select id="name1" 
                  class="form-control"
                  v-model="job_role"
                  >
                    <option value="">Job Role</option>
                    <option value="HR">HR</option>
                    <option value="Executive">Executive</option>
                    <option value="Business Development">Business Development</option>
                    <option value="other">other</option>
                  </select>
                  <p v-if="jobRoleError" class="error-message">
                    Please Select Job Role
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <select id="name1"
                  class="form-control"
                  v-model="query_type"
                  >
                    <option value="">Query Type</option>
                    <option value="Development">Development</option>
                    <option value="Management And Training">Management And Training</option>
                    <option value="Hiring">Hiring</option>
                    <option value="Others">Others</option>
                  </select>
                  <p v-if="queryTypeError" class="error-message">
                    Please Select Query Type
                  </p>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea
                    name="message"
                    class="form-control"
                    id="message"
                    cols="30"
                    rows="8"
                    placeholder="Your Message"
                    v-model="message"
                  ></textarea>
                  <p v-if="messageError" class="error-message">
                    Please enter your message
                  </p>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="submit-btn text-center">
                  <button type="submit" class="default-btn">
                    Send Your Messgae
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      company_name: "",
      job_role: "",
      query_type: "",
      mobile: "",
      subjectError: false,
      mobileError: false,
      nameError: false,
      emailError: false,
      messageError: false,
      success:false ,
      showSuccessPopup: false, // Add this line

      
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault(); 
      this.FirstNameErrorError = false;
      this.lastNameError = false;
      this.emailError = false;
      this.messageError = false;
      this.companyError = false;
      this.queryTypeError = false;
      this.jobRoleError = false;
      this.mobileError = false;
      


      if (!this.firstName) {
        this.FirstNameError = true;
      }
      if (!this.lastName) {
        this.lastNameError = true;
      }
      if (!this.email || !this.isValidEmail(this.email)) {
        this.emailError = true;
      }
      if (!this.message) {
        this.messageError = true;
      }
      if (!this.mobile) {
        this.mobileError = true;
      }
      if (!this.company_name) {
        this.companyError = true;
      }
      if (!this.query_type) {
        this.queryTypeError = true;
      }if (!this.job_role) {
        this.jobRoleError = true;
      }

      console.log(this)
      if (
        !this.FirstNameError && !this.lastNameError && 
        !this.emailError &&
        !this.messageError &&
        !this.mobileError &&
        !this.companyError &&  !this.jobRoleError &&  !this.queryTypeError 
      ) {
        const data = {
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
          query: this.message,
          mobile: this.mobile,
          subject: this.subject,
          jobRole:this.job_role,
          queryType:this.query_type,
          countryCode:"+91"
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios
          .post(
            "https://api.upraiss.com/v0/leades/create-lead",
            data,
            config
          )
          .then((response) => {
            this.success=true
            // alert(response.data.message)
            this.showSuccessPopup = true; // Show success popup

            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });

        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.message = "";
        this.company_name = "";
        this.job_role = "";
        this.query_type = "";
        this.mobile = "";

        setTimeout(() => {
          this.success=false

        },10000);
      }
    },
    isValidEmail(email) {

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      

      return emailRegex.test(email);
    },
  },
  name: "ContactContent",
};
</script>

<style lang="scss">


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}







.error-message {
  color: red;
  font-size: 12px;
}

.form-group {
  margin-bottom: 20px;
}

.inner-massage {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  margin-bottom: 40px;
  padding-top: 50px;
  padding-bottom: 60px;
}

.massage-box {
  padding: 0px 45px 0px 45px;
  text-align: center;
  border-right: solid 1px var(--borderColor);

  .icon {
    height: 80px;
    width: 80px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 90px;
    border-radius: 50%;
    font-size: 30px;
    margin-bottom: 20px;
    transition: var(--transition);
    color: var(--hoverColor);
    background: rgba(255, 65, 75, 0.1);

    &:hover {
      background-color: var(--hoverColor);
      color: var(--whiteColor);
    }
  }

  .content {
    h5 {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
}

.col-lg-4 {
  &:nth-child(3) {
    .massage-box {
      border: none;
    }
  }
}

.contact-widget {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form-area {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  padding: 50px 30px;

  .form-control {
    background: #f4f8fc;
    border-radius: 5px;
    height: 60px;
    padding-left: 18px;
    color: #888888;
    border: none;

    &:focus {
      box-shadow: unset;
    }

    &::placeholder {
      color: #888888;
    }
  }

  textarea.form-control {
    min-height: 180px;
    padding-top: 18px;
  }
}
</style>



