<template>
    <div class="preloader">
        <div class="loader">
            <div class="preloader-word">
                <div class="waviy">
                    <span>B</span>
                    <span>U</span>
                    <span>I</span>
                    <span>L</span>
                    <span>D</span>
                    <span>Q</span>
                    <span>U</span>
                    <span>I</span>
                    <span>C</span>
                    <span>K</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreLoader'
}
</script>

<style lang="scss">
.preloader-word {
    margin-top: -20px;
    top: 46%;
    margin: auto;
    text-align: center;
    right: 0px;
    left: 0px;
    position: absolute;

    .waviy {
        -webkit-box-reflect: below -46px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
        font-size: 50px;
        font-weight: 700;

        span {
            -webkit-animation-delay: .1s;
            animation-delay: .1s;
            -webkit-animation: waviy 1s infinite;
            animation: waviy 1s infinite;
            -webkit-animation-delay: .2s;
            animation-delay: .2s;
            margin: 0 6px;

            &:nth-child(1) {
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
            }

            &:nth-child(2) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .2s;
                animation-delay: .2s;
            }

            &:nth-child(3) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .3s;
                animation-delay: .3s;
            }

            &:nth-child(4) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .4s;
                animation-delay: .4s;
            }

            &:nth-child(5) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .25s;
                animation-delay: .5s;
            }

            &:nth-child(6) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .6s;
                animation-delay: .6s;
            }

            &:nth-child(7) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .7s;
                animation-delay: .7s;
            }

            &:nth-child(8) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .8s;
                animation-delay: .8s;
            }

            &:nth-child(9) {
                --tw-text-opacity: 1;
                display: inline-block;
                position: relative;
                --tw-text-opacity: 1;
                color: rgb(239 93 93 / var(--tw-text-opacity));
                -webkit-animation-delay: .9s;
                animation-delay: .9s;

            }
        }
    }
}

@-webkit-keyframes waviy {

    0%,
    40%,
    100% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-20px);
    }
}

@keyframes waviy {

    0%,
    40%,
    100% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-20px);
    }
}

.preloader {
    position: fixed;
    width: 100%;
    z-index: 9;
    height: 100%;
    background: #ffffff;
    color: #F11928;
    top: 0;
    left: 0;
    text-align: center;
}
</style>