

<template>
    <div class="map-area">
        <div class="map">
            <div class="location-map">
                <!-- <iframe
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=2nd Floor, VentureX, 389-390, Sector 29, Gurgram Haryana, 122002&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> -->

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.4196588755963!2d77.06371467528271!3d28.46690577575556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18fc94cdeaad%3A0x7df9d3ea522ae73e!2sBuildQuick!5e0!3m2!1sen!2sin!4v1721972399189!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapContent'
}
</script>

<style lang="scss">
.map-area {
    iframe {
        height: 500px;
        width: 100%;
        border: none;
        margin-bottom: -10px;
    }
}
</style>

<style>
.mapouter {
    position: relative;
    text-align: right;
    width: 600px;
    height: 400px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 600px;
    height: 400px;
}

.gmap_iframe {
    width: 600px !important;
    height: 400px !important;
}
</style>



  



  



  













<!-- <template>
    <div class="map-area">
        <div class="map">
            <div class="location-map">
                <iframe :src="mapUrl" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapContent',
    data() {
        return {
            latitude: 28.4684149,
            longitude: 77.0684354,
            zoom: 15
        }
    },
    computed: {
        mapUrl() {
            return `https://maps.google.com/maps?q=${this.latitude},${this.longitude}&z=${this.zoom}&output=embed`
        }
    }
}
</script>

<style lang="scss">
.map-area {
    iframe {
        height: 500px;
        width: 100%;
        border: none;
        margin-bottom: -10px;
    }
}
</style>

<style>
.mapouter {
    position: relative;
    text-align: right;
    width: 600px;
    height: 400px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 600px;
    height: 400px;
}

.gmap_iframe {
    width: 600px !important;
    height: 400px !important;
}
</style> -->
